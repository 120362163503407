import { useEffect, useState } from 'react';

export const ACCESS_TOKEN_KEY = 'accessToken';

export const useAuth = () => {
  const [isLogin, setLogin] = useState<boolean>();

  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    setLogin(!!accessToken);
  }, []);

  return {
    isLogin: isLogin,
    setAuth: (accessToken: string) => {
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      setLogin(true);
    },
    logout: () => {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      setLogin(false);
    },
  };
};
