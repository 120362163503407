export const formatBusinessNumber = (value?: string) => {
  if (!value) {
    return '';
  }
  const cleaned = value.replace(/\D+/g, '');
  if (cleaned.length <= 3) {
    return cleaned;
  } else if (cleaned.length <= 5) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
  } else {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 5)}-${cleaned.slice(5, 10)}`;
  }
};

export const formatDate = (value?: Date | string) => {
  if (!value) {
    return '';
  }

  const date = typeof value === 'string' ? new Date(value) : value;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
