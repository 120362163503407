import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Switch,
  Typography,
} from 'antd';
import { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmCreatedServiceAccountModal from '../../components/ConfirmCreatedProductModal';
import { useGetPaymentMethodsQuery } from '../../generated/api/axios-client/PaymentControllerQuery';
import {
  useGetCdnQuery,
  useGetCurrencyQuery,
  useGetGroupsQuery,
  useGetIndexOfEnterprisePlanQuery,
  useGetPocTypeQuery,
} from '../../generated/api/axios-client/ShopAccountControllerQuery';
import { useNotification } from '../../hooks/notification';
import { formatBusinessNumber } from '../../utils/format';

type CreateServiceAccountFormProps = {
  bitrate: number;
  cdn: number;
  currency: number;
  duration: [Dayjs, Dayjs];
  priceUnit: number;
  freeTestDuration: [Dayjs, Dayjs];
  functionRange: 'shopplayer' | 'displayer';
  groupId: number;
  maxDuration: number;
  paymentMethod: number;
  shopName: string;
  shopUrl: string;
  enabledTransferStatistics: boolean;
  maxContents: number;
  pocType: number;
};

const CreateUserPage: React.FC = () => {
  const { data: groups } = useGetGroupsQuery();
  const { data: paymentMethods } = useGetPaymentMethodsQuery();
  const { data: currency } = useGetCurrencyQuery();
  const { data: cdns } = useGetCdnQuery();
  const { data: index } = useGetIndexOfEnterprisePlanQuery();
  const { data: pocTypes } = useGetPocTypeQuery();
  const [createdShopAccountId, setCreatedShopAccountId] = useState<number>();

  const notification = useNotification();
  const navigate = useNavigate();

  const [form] = Form.useForm<CreateServiceAccountFormProps>();
  const [businessRegistration, setBusinessRegistration] = useState<string>('');
  const [basicPrice, setBasicPrice] = useState<string>('');
  const [maxPlayed, setMaxPlayed] = useState<string>('');

  const handleBusinessNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value.replace(/\D+/g, ''); // 숫자만 유지
    if (inputValue.length <= 10) {
      setBusinessRegistration(inputValue);
    }
  };

  const newProductName = useMemo(() => {
    if (!index) {
      return 'enterprise';
    }

    const newId = index + 1;
    return `enterprise${newId}`;
  }, [index]);

  const onSubmit = () => {
    // const {
    //   bitrate,
    //   cdn,
    //   currency,
    //   priceUnit,
    //   functionRange,
    //   groupId,
    //   maxDuration,
    //   paymentMethod,
    //   shopName,
    //   shopUrl,
    //   enabledTransferStatistics,
    //   maxContents,
    //   pocType,
    //   duration,
    //   freeTestDuration,
    // } = values;
    // const basicPriceNumber = Number(basicPrice.replace(/,/g, ''));
    // const maxPlayedNumber = Number(maxPlayed.replace(/,/g, ''));
    // const pocStartAt = freeTestDuration[0]?.toDate();
    // const pocEndAt = freeTestDuration[1]?.toDate();
    // const paymentStartAt = duration[0]?.toDate();
    // const paymentEndAt = duration[1]?.toDate();
  };

  const pricePerPlayed = useMemo(() => {
    if (!basicPrice || !maxPlayed) {
      return;
    }

    const basicPriceNumber = Number(basicPrice.replace(/,/g, ''));
    const maxPlayedNumber = Number(maxPlayed.replace(/,/g, ''));

    return (basicPriceNumber / maxPlayedNumber).toFixed(1);
  }, [basicPrice, maxPlayed]);

  return (
    <>
      <Form layout="horizontal" form={form} onFinish={onSubmit}>
        <Typography.Title level={3}>고객 정보</Typography.Title>
        <Form.Item
          label="그룹"
          name="groupId"
          rules={[{ required: true, message: '그룹을 선택해 주세요.' }]}
        >
          <Select placeholder="그룹 선택" style={{ width: '240px' }}>
            {groups?.map((group) => (
              <Select.Option key={`group_${group.id}`} value={group.id}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Flex align="center" gap={20}>
          <Form.Item
            label="쇼핑몰 이름"
            name="shopName"
            rules={[
              { required: true, message: '쇼핑몰 이름을 입력해 주세요.' },
            ]}
          >
            <Input placeholder="쇼핑몰 이름" style={{ width: '240px' }} />
          </Form.Item>
          <Form.Item label="쇼핑몰 주소" name="shopUrl">
            <Input placeholder="www.example.com" style={{ width: '240px' }} />
          </Form.Item>
        </Flex>
        <Form.Item
          label="사업자 등록번호"
          rules={[
            { required: true, message: '사업자 등록번호를 입력해 주세요.' },
          ]}
        >
          <Input
            placeholder="000-00-00000"
            style={{ width: '240px' }}
            onChange={handleBusinessNumberChange}
            value={formatBusinessNumber(businessRegistration)}
            maxLength={13}
          />
        </Form.Item>
        <Typography.Title level={3} className="mt-16">
          엔터프라이즈 플랜 계약 조건
        </Typography.Title>
        <Typography.Paragraph style={{ color: '#747474' }}>
          AM이 업체 계정을 직접 생성하는 경우 새로운 엔터프라이즈 플랜이
          추가됩니다.
        </Typography.Paragraph>
        <Flex align="center" gap={20}>
          <Form.Item label="플랜명">
            <Input value={newProductName} disabled width="240px" readOnly />
          </Form.Item>
          <Form.Item label="구분">
            <Input disabled width="240px" value="-" />
          </Form.Item>
          <Form.Item label="생성일">
            <Input disabled width="240px" readOnly value="-" />
          </Form.Item>
        </Flex>
        <Flex align="center" gap={20}>
          <Form.Item
            label="통화"
            name="currency"
            rules={[{ required: true, message: '통화를 선택해 주세요.' }]}
          >
            <Select placeholder="통화 선택" style={{ width: '240px' }}>
              {currency?.map((currency) => (
                <Select.Option
                  key={`currency_${currency.id}`}
                  value={currency.id}
                >
                  {currency.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="CDN"
            name="cdn"
            rules={[{ required: true, message: 'CDN을 선택해 주세요.' }]}
          >
            <Select placeholder="CDN 선택" style={{ width: '240px' }}>
              {cdns?.map((cdn) => (
                <Select.Option key={`cdn_${cdn.id}`} value={cdn.id}>
                  {cdn.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="전송량 통계 보이기"
            name="enabledTransferStatistics"
            required
          >
            <Switch />
          </Form.Item>
        </Flex>
        <Typography.Paragraph style={{ color: '#747474', marginTop: '32px' }}>
          해당 영역의 모든 입력칸에는 숫자만 입력해 주세요.
        </Typography.Paragraph>
        <Flex align="center" gap={20}>
          <Form.Item
            label="월 계약 금액"
            rules={[{ required: true, message: '금액을 입력해 주세요.' }]}
          >
            <InputNumber
              value={basicPrice}
              onChange={(value) =>
                value && setBasicPrice(value.toLocaleString())
              }
              placeholder="100,000"
              style={{ width: '240px' }}
            />
          </Form.Item>

          <Form.Item label="로드 수" required>
            <InputNumber
              value={maxPlayed}
              onChange={(value) =>
                value && setMaxPlayed(value.toLocaleString())
              }
              placeholder="1,000,000"
              style={{ width: '240px' }}
              addonAfter={'회'}
            />
          </Form.Item>

          <Form.Item label="업로드 최대 수" name="maxContents" required>
            <InputNumber
              placeholder="200"
              style={{ width: '240px' }}
              addonAfter={'개'}
            />
          </Form.Item>
        </Flex>
        <Flex align="center" gap={20}>
          <Form.Item label="계약 단가">
            <InputNumber
              placeholder="월 계약 금액, 로드수를 입력해 주세요."
              style={{ width: '240px' }}
              value={pricePerPlayed}
              readOnly
            />
          </Form.Item>

          <Form.Item label="초과 재생 단가" name="priceUnit" required>
            <InputNumber placeholder="2" style={{ width: '240px' }} />
          </Form.Item>
        </Flex>
        <Flex align="center" gap={20}>
          <Form.Item label="동영상 최대 길이" name="maxDuration" required>
            <InputNumber
              placeholder="60"
              style={{ width: '240px' }}
              addonAfter={'s'}
            />
          </Form.Item>

          <Form.Item label="비트레이트(Mbps)" name="bitrate" required>
            <Select placeholder="비트레이트 선택" style={{ width: '240px' }}>
              <Select.Option value={1}>1</Select.Option>
              <Select.Option value={2}>2</Select.Option>
              <Select.Option value={3}>3</Select.Option>
              <Select.Option value={4}>4</Select.Option>
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={10}>10</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="기능 범위"
            name="functionRange"
            rules={[{ required: true, message: '기능 범위를 선택해 주세요.' }]}
          >
            <Select placeholder="기능 범위 선택" style={{ width: '240px' }}>
              <Select.Option value="shopplayer">샵 플레이어</Select.Option>
              <Select.Option value="displayer">디스플레이어</Select.Option>
            </Select>
          </Form.Item>
        </Flex>
        <Flex align="center" gap={20} className="mt-12">
          <Form.Item
            label="무료 체험 기간"
            name="freeTestDuration"
            required
            rules={[
              { required: true, message: '무료체험기간을 선택해 주세요.' },
            ]}
          >
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item
            label="총 계약 기간"
            name="duration"
            rules={[
              { required: true, message: '총 계약기간을 선택해 주세요.' },
            ]}
            required
          >
            <DatePicker.RangePicker />
          </Form.Item>
        </Flex>
        <Flex align="center" gap={20} className="mt-12">
          <Form.Item
            label="무료 체험 유형"
            name="pocType"
            rules={[
              { required: true, message: '무료체험 유형을 선택해 주세요.' },
            ]}
          >
            <Radio.Group>
              {pocTypes?.map((pocType) => {
                /*
                  PocType.id === 2는 [플랜변경]으로, 여기선 해당되지 않는다.
                */
                if (pocType.id === 2) {
                  return;
                }
                return (
                  <Radio key={`pocType_${pocType.id}`} value={pocType.id}>
                    {pocType.name}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="결제 방식"
            name="paymentMethod"
            rules={[{ required: true, message: '결제 방식을 선택해 주세요.' }]}
          >
            <Select placeholder="결제 방식 선택" style={{ width: '240px' }}>
              {paymentMethods?.map((paymentMethod) => {
                const name =
                  paymentMethod.name === 'TOSS'
                    ? '토스_선불'
                    : paymentMethod.name;

                return (
                  <Select.Option
                    key={`paymentMethod_${paymentMethod.id}`}
                    value={paymentMethod.id}
                    disabled={paymentMethod.name === 'STRIPE'}
                  >
                    {name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Flex>

        {/* TODO: 기능 구현시 추가*/}
        {/* <Form.Item label="담당 AM" name="responsibleAM" required>
        <Select placeholder="담당 AM 선택" style={{ width: '240px' }}>
          <Select.Option value="am1">김철수</Select.Option>
          <Select.Option value="am2">이영희</Select.Option>
        </Select>
      </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            생성
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title="업체 계정 생성 확인"
        open={createdShopAccountId !== undefined}
        onCancel={() => setCreatedShopAccountId(undefined)}
        onOk={() => {
          navigator.clipboard
            .writeText(
              `${process.env.REACT_APP_CHARLLA_HOST}/account/signup/connect/${createdShopAccountId}`,
            )
            .then(() => {
              notification.notify.success({
                message:
                  '클립보드에 링크가 복사되었습니다. 고객사에 전달해 주세요',
              });
              navigate(`/user/${createdShopAccountId}`);
            })
            .catch((err) => {
              console.error('복사 실패: ', err);
            });
        }}
        okText="확인 및 링크복사"
        cancelText="취소"
      >
        {createdShopAccountId && (
          <ConfirmCreatedServiceAccountModal
            shopAccountId={createdShopAccountId}
          />
        )}
      </Modal>
    </>
  );
};

export default CreateUserPage;
