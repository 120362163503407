import { SaveOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Descriptions, DescriptionsProps, Flex, Input } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  MallInformationControllerQuery,
  ServiceAccountControllerQuery,
} from '../../generated/api/axios-client';
import { useNotification } from '../../hooks/notification';

const ServiceAccountPage: React.FC = () => {
  const { serviceAccountId } = useParams<{ serviceAccountId: string }>();
  const queryClient = useQueryClient();
  const notification = useNotification();
  const [mallBaseDomainForUpdate, setMallBaseDomainForUpdate] =
    useState<string>();

  const { data, refetch } = ServiceAccountControllerQuery.useGetQuery({
    id: Number(serviceAccountId),
  });

  const updateMallInformation =
    MallInformationControllerQuery.useUpdateBaseDomainMutation(
      data?.mallInformationId ? Number(data.mallInformationId) : 0,
    );

  const updateBillingKey =
    ServiceAccountControllerQuery.useUpdateBillingKeyIdMutation(
      Number(serviceAccountId),
    );

  const items = useMemo<DescriptionsProps['items']>(() => {
    if (!data) {
      return [];
    }

    const mallBaseDomainChanged =
      data.mallInformation?.baseDomain !== mallBaseDomainForUpdate;

    const handleSave = () => {
      if (!mallBaseDomainForUpdate) {
        return;
      }

      if (mallBaseDomainForUpdate === data.mallInformation?.baseDomain) {
        return;
      }

      updateMallInformation.mutate(
        {
          baseDomain: mallBaseDomainForUpdate,
        } as any,
        {
          onSuccess: () => {
            refetch();
            notification.notify.success({
              message: '베이스 도메인이 성공적으로 수정되었습니다.',
            });
          },
          onSettled: () => {
            queryClient.invalidateQueries({
              queryKey: ['ServiceAccountControllerClient', 'list'],
              exact: false,
            });
          },
        },
      );
    };

    const description: DescriptionsProps['items'] = [
      {
        key: 1,
        label: 'ID',
        children: <p>{data.id}</p>,
        span: 2,
      },
      {
        key: 2,
        label: '서비스명',
        children: <p>{data.serviceName}</p>,
        span: 2,
      },
      {
        key: 3,
        label: '빌링키 ID',
        children: <p>{data.billingKeyId}</p>,
        span: 2,
      },
      {
        key: 4,
        label: '몰 ID',
        children: (
          <p>
            {data.mallInformationId ? data.mallInformationId : '(알 수 없음)'}
          </p>
        ),
      },
      {
        key: 5,
        label: '몰 Base Domain',
        children: (
          <Flex gap={8}>
            <Input
              type="url"
              value={mallBaseDomainForUpdate}
              placeholder="Mall Base Domain"
              onChange={(e) => setMallBaseDomainForUpdate(e.target.value)}
              onPressEnter={handleSave}
            />

            <Button
              icon={<SaveOutlined />}
              disabled={!mallBaseDomainChanged}
              onClick={handleSave}
            />
          </Flex>
        ),
      },
    ];

    return description;
  }, [data, mallBaseDomainForUpdate, updateMallInformation]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setMallBaseDomainForUpdate(data.mallInformation?.baseDomain);
  }, [data]);

  const handleClickExcludBillingKey = () => {
    if (!confirm('정말 빌링키를 제외하시겠습니까?')) {
      return;
    }
    updateBillingKey.mutate(undefined, {
      onSuccess: () => {
        if (!data) {
          return;
        }
        ServiceAccountControllerQuery.setGetData(
          queryClient,
          (prev) => {
            const updated: any = {
              id: data.id,
              billingKeyId: data.billingKeyId,
              serviceName: data.serviceName,
            };

            return {
              ...updated,
              ...prev,
              billingKeyId: 0,
            };
          },
          Number(serviceAccountId),
        );
        notification.notify.success({
          message: '빌링키가 정상적으로 제외되었습니다',
        });
      },
      onError: () => {
        notification.notify.error({
          message: '빌링키 제외에 실패하였습니다. 관리자에게 문의해주세요.',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ['ServiceAccountControllerClient', 'list'],
          exact: false,
        });
      },
    });
  };

  return (
    <Flex className="w-full" vertical gap={'16px'}>
      <Flex>
        <Button
          disabled={data?.billingKeyId === 0}
          onClick={handleClickExcludBillingKey}
        >
          빌링키 제외
        </Button>
      </Flex>

      <Descriptions items={items} bordered style={{ width: '100%' }} />
    </Flex>
  );
};

export default ServiceAccountPage;
