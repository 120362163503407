import { Descriptions, DescriptionsProps, Flex, Typography } from 'antd';
import { useMemo } from 'react';

import { useGetPaymentMethodsQuery } from '../generated/api/axios-client/PaymentControllerQuery';
import {
  useGetBillQuery,
  useGetPlanQuery,
} from '../generated/api/axios-client/ShopAccountControllerQuery';
import { formatDate } from '../utils/format';

type ConfirmCreatedProductModalProps = {
  shopAccountId: number;
};

const ConfirmCreatedProductModal: React.FC<ConfirmCreatedProductModalProps> = ({
  shopAccountId,
}) => {
  const { data } = useGetPlanQuery({ shopAccountId });
  const { data: paymentMethods } = useGetPaymentMethodsQuery();
  const { data: bill } = useGetBillQuery({
    shopAccountId,
  });

  const items = useMemo<DescriptionsProps['items']>(() => {
    if (!data || !bill) {
      return [];
    }

    let paymentMethod = '';

    if (paymentMethods) {
      const matchedPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.id === data.paymentMethodId,
      );
      if (matchedPaymentMethod) {
        paymentMethod = matchedPaymentMethod.name;
      }
    }

    return [
      {
        key: '0',
        label: '고객 정보',
        children: <p></p>,
        labelStyle: {
          backgroundColor: '#ffffff',
          border: 'none',
          fontWeight: 'bold',
        },
        span: 4,
      },
      {
        key: '1',
        label: '플랜명',
        children: <p>{data.name}</p>,
        span: 4,
      },
      {
        key: '2',
        label: '구분',
        children: <p>-</p>,
        span: 4,
      },
      {
        key: '3',
        label: '생성일',
        children: <p>-</p>,
        span: 4,
      },
      {
        key: '4',
        label: '통화',
        children: <p>{data.currency?.name}</p>,
        span: 4,
      },
      {
        key: '5',
        label: 'CDN',
        children: <p>{data.cdn?.name}</p>,
        span: 4,
      },
      {
        key: '6',
        label: '전송량 통계 보이기',
        children: <p>{data.enabledTransferStatistics ? 'on' : 'off'}</p>,
        span: 4,
      },
      {
        key: '8',
        label: '엔터프라이즈플랜 계약조건',
        children: <p></p>,
        labelStyle: {
          backgroundColor: '#ffffff',
          border: 'none',
          fontWeight: 'bold',
        },
        span: 4,
      },
      {
        key: '9',
        label: '월 계약금액(=공급 가액)',
        children: <p>{data.basicPrice.toLocaleString()}</p>,
        span: 4,
      },
      {
        key: '10',
        label: '로드수',
        children: <p>{data.maxPlayed}회</p>,
        span: 4,
      },
      {
        key: '11',
        label: '업로드 최대 수',
        children: <p>{data.maxContents}회</p>,
        span: 4,
      },
      {
        key: '12',
        label: '계약 단가',
        children: <p>{(data.basicPrice / data.maxPlayed).toFixed(1)}</p>,
        span: 4,
      },
      {
        key: '13',
        label: '초과금 재생 단가',
        children: <p>{data.priceUnit}</p>,
        span: 4,
      },
      {
        key: '14',
        label: '동영상 최대길이',
        children: <p>{data.maxDuration}s</p>,
        span: 4,
      },
      {
        key: '15',
        label: '비트레이트 기본값',
        children: <p>{data.bitrate}Mbps</p>,
        span: 4,
      },
      {
        key: '16',
        label: '기능 범위',
        children: (
          <p>{data.enabledShopplayer ? '샵플레이어' : '디스플레이어'}</p>
        ),
        span: 4,
      },
      {
        key: '17',
        label: '무료체험 기간',
        children: (
          <p>
            {formatDate(data.pocStartAt)} - {formatDate(data.pocEndAt)}
          </p>
        ),
        span: 4,
      },
      {
        key: '18',
        label: '총 계약기간',
        children: (
          <p>
            {formatDate(bill?.collectStartAt)} -{' '}
            {formatDate(bill?.collectStartAt)}
          </p>
        ),
        span: 4,
      },
      {
        key: '19',
        label: '과금 방식',
        children: <p>{paymentMethod}</p>,
        span: 4,
      },
    ];
  }, [data, paymentMethods, bill]);
  return (
    <Flex vertical className="pt-4">
      <div style={{ height: '50vh', overflow: 'scroll' }}>
        <Descriptions items={items} bordered />
      </div>
      <Typography.Title className="mt-4" level={5}>
        확인 버튼을 클릭하고 업체 관리 목록에서 가입 링크 복사 버튼을 누르세요.
        복사된 링크를 <span style={{ color: '#FA602F' }}>고객에게 전달</span>해
        주세요. 고객이 로그인 정보를 입력하면 계정 생성이 완료됩니다.
      </Typography.Title>
    </Flex>
  );
};

export default ConfirmCreatedProductModal;
