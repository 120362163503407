import { RetweetOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Table, TableColumnsType } from 'antd';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ServiceAccountControllerQuery } from '../../generated/api/axios-client';

const ServiceAccountsPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const limit = 10;
  const [term, setTerm] = useState<string>('');
  const { page } = useParams<{ page: string }>();

  const currentPage = page ? parseInt(page) : 1;
  const offset = (currentPage - 1) * limit;
  const { data, isLoading } = ServiceAccountControllerQuery.useListQuery({
    offset,
    limit,
    term: searchParams.get('term') || undefined,
  });

  const columns: TableColumnsType = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '서비스 계정 이름',
      dataIndex: 'serviceName',
    },
    {
      title: '빌링키 ID',
      dataIndex: 'billingKeyId',
    },
    {
      title: '몰 ID',
      dataIndex: 'mallId',
    },
  ];

  const updateSearchParams = (queryKey: string, value: string) => {
    const newParams = new URLSearchParams(window.location.search);

    if (value) {
      newParams.set(queryKey, value);
    } else {
      newParams.delete(queryKey);
    }

    navigate({
      pathname: '/service-accounts',
      search: newParams.toString(),
    });
  };

  const useDebouncedSearch = (queryKey: string) => {
    return useCallback(
      debounce((value: string) => {
        updateSearchParams(queryKey, value);
      }, 500),
      [searchParams, queryKey],
    );
  };

  const updateTermSearchParams = useDebouncedSearch('term');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTerm(value);
    updateTermSearchParams(value);
  };

  const dataSource = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.serviceAccounts.map((serviceAccount) => ({
      key: serviceAccount.id,
      mallId: serviceAccount.mallInformationId
        ? serviceAccount.mallInformationId
        : '(알 수 없음)',
      ...serviceAccount,
    }));
  }, [data]);

  const count = useMemo(() => {
    if (!data) {
      return 0;
    }

    return data.count;
  }, [data]);

  return (
    <Flex vertical gap="16px">
      <Flex justify="space-between">
        <Input.Search
          style={{ width: '40%' }}
          onChange={handleSearchChange}
          placeholder="서비스 계정 이름"
          value={term ? term : undefined}
          defaultValue={searchParams.get('term') || undefined}
          loading={isLoading}
        />
        <Flex align="center" gap={4}>
          <Button
            icon={<RetweetOutlined />}
            type="text"
            size="large"
            onClick={() => {
              setTerm('');
              navigate('/service-accounts');
            }}
          />
        </Flex>
      </Flex>

      {/* <Descriptions items={filters} bordered size="small" /> */}
      <Table
        dataSource={dataSource}
        columns={columns}
        rowClassName={() => 'clickable-row'}
        loading={isLoading}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`/service-account/${record.key}`);
            },
          };
        }}
        pagination={{
          total: count,
          onChange: (page) => {
            const newParams = new URLSearchParams(searchParams.toString());
            navigate({
              pathname: `/service-accounts/${page}`,
              search: newParams.toString(),
            });
          },
          showSizeChanger: false,
          current: currentPage,
        }}
      />
    </Flex>
  );
};

export default ServiceAccountsPage;
