import { notification } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';
import React, { createContext, useContext } from 'react';

type NotificationContextType = {
  notify: NotificationInstance;
  contextHolder: React.ReactNode;
};

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined,
);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <NotificationContext.Provider value={{ notify: api, contextHolder }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    );
  }

  return context;
};
