import { Descriptions, DescriptionsProps, Flex, Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import ConfirmCreatedProductModal from '../../components/ConfirmCreatedProductModal';
import { useGetShopAccountQuery } from '../../generated/api/axios-client/ShopAccountControllerQuery';
import { useNotification } from '../../hooks/notification';
import { formatBusinessNumber } from '../../utils/format';

const UserPage: React.FC = () => {
  const { shopAccountId } = useParams<{ shopAccountId: string }>();
  const [modal, setModal] = useState(false);
  const notification = useNotification();

  const { data } = useGetShopAccountQuery({
    shopAccountId: shopAccountId ? Number(shopAccountId) : 0,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!data.loginMail) {
      setModal(true);
    }
  }, [data]);

  const items = useMemo<DescriptionsProps['items']>(() => {
    if (!data) {
      return [];
    }

    const description: DescriptionsProps['items'] = [
      {
        key: 1,
        label: '업체 이름',
        children: <p>{data.mallName}</p>,
        span: 2,
      },
      {
        key: 2,
        label: '쇼핑몰 ID',
        children: <p>{data.mallId}</p>,
        span: 2,
      },
      {
        key: 3,
        label: '대표 로그인 이메일',
        children: <p>{data.loginMail}</p>,
        span: 2,
      },
      {
        key: 4,
        label: '가입일',
        children: <p>{data.createdAt}</p>,
        span: 2,
      },
      {
        key: 5,
        label: '그룹',
        children: <p>{data.group?.name}</p>,
        span: 2,
      },
      {
        key: 6,
        label: '플랜',
        children: <p>{data.plan.name}</p>,
        span: 2,
      },
      {
        key: 7,
        label: '최근 결제일',
        children: <p>{data.latestPaidAt}</p>,
        span: 2,
      },
      {
        key: 8,
        label: '계정 상태',
        children: <p>{data.status?.name}</p>,
        span: 2,
      },
      {
        key: 9,
        label: '해지 이유',
        children: <p>{data.withdrawReason}</p>,
        span: 2,
      },
      {
        key: 10,
        label: '사업자 등록번호',
        children: <p>{formatBusinessNumber(data.registrationNumber)}</p>,
        span: 2,
      },
      {
        key: 11,
        label: '쇼핑몰 도메인',
        children: <p>{data.mallUrl}</p>,
        span: 2,
      },
      {
        key: 12,
        label: '기타 메모',
        children: <p>{data.memo}</p>,
        span: 2,
      },
    ];

    return description;
  }, [data]);

  return (
    <>
      <Flex className="w-full">
        <Descriptions
          title={data?.mallName}
          items={items}
          bordered
          style={{ width: '100%' }}
        />
      </Flex>
      <Modal
        title="업체 계정 생성 확인"
        open={modal}
        onCancel={() => setModal(false)}
        onOk={() => {
          navigator.clipboard
            .writeText(
              `${process.env.REACT_APP_CHARLLA_HOST}/account/signup/connect/${shopAccountId}`,
            )
            .then(() => {
              notification.notify.success({
                message:
                  '클립보드에 링크가 복사되었습니다. 고객사에 전달해 주세요',
              });
              setModal(false);
            })
            .catch((err) => {
              console.error('복사 실패: ', err);
            });
        }}
        okText="확인 및 링크복사"
        cancelText="취소"
      >
        {shopAccountId && (
          <ConfirmCreatedProductModal shopAccountId={Number(shopAccountId)} />
        )}
      </Modal>
    </>
  );
};

export default UserPage;
