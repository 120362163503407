import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';

import { ACCESS_TOKEN_KEY } from './auth';
import { ServiceAccount, ShopAccount } from '../generated/api/axios-client';
import { getQueryKey } from '../generated/api/axios-client/ServiceAccountControllerQuery';

let API_HOST_URL = process.env.REACT_APP_API_HOST;

if (process.env.REACT_APP_API_HOST) {
  if (window.location.hostname === 'admin.charlla.io') {
    API_HOST_URL = 'https://admin-api.charlla.io';
  } else if (
    window.location.hostname === 'dev-admin.charlla.io' ||
    window.location.hostname === 'stg-admin.charlla.io'
  ) {
    API_HOST_URL = 'https://stg-admin-api.charlla.io';
  }
}

export const useGetShopAccountLazyQuery = (): [
  (shopAccountId: number) => Promise<ShopAccount | undefined>,
  ShopAccount | undefined,
  { refetch: (shopAccountId: number) => void },
] => {
  const queryClient = useQueryClient();
  const [user, setUser] = useState<ShopAccount>();
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  const fetch = async (shopAccountId: number) => {
    try {
      const cachedData = queryClient.getQueryData([
        'getShopAccount',
        shopAccountId,
      ]);

      if (cachedData) {
        return cachedData;
      }

      if (user) {
        return user;
      }
      const data = (
        await axios.get(`${API_HOST_URL}/shop-account/${shopAccountId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      ).data;
      setUser(data);
      queryClient.setQueryData(['getShopAccount', shopAccountId], data);

      return data;
    } catch (err) {
      console.error(err);
      return;
    }
  };

  const refetch = async (shopAccountId: number) => {
    const data = (
      await axios.get(`${API_HOST_URL}/shop-account/${shopAccountId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data;

    setUser(data);
    queryClient.setQueryData(['getShopAccount', shopAccountId], data);
  };

  return [fetch, user, { refetch }];
};

export const useGetServiceAccountLazyQuery = (): [
  (serviceAccountId: number) => Promise<ServiceAccount | undefined>,
  ServiceAccount | undefined,
  { refetch: (serviceAccountId: number) => void },
] => {
  const queryClient = useQueryClient();
  const [serviceAccount, setServiceAccount] = useState<ServiceAccount>();
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  const fetch = async (serviceAccountId: number) => {
    try {
      const queryKey = getQueryKey(serviceAccountId);
      const cachedData = queryClient.getQueryData<ServiceAccount>(queryKey);

      if (cachedData) {
        return cachedData;
      }

      if (serviceAccount) {
        return serviceAccount;
      }
      const { data } = await axios.get<ServiceAccount>(
        `${API_HOST_URL}/service-accounts/${serviceAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      setServiceAccount(data);
      queryClient.setQueryData(queryKey, data);

      return data;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  const refetch = async (serviceAccountId: number) => {
    try {
      const queryKey = getQueryKey(serviceAccountId);
      const { data } = await axios.get<ServiceAccount>(
        `${API_HOST_URL}/service-accounts/${serviceAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      setServiceAccount(data);
      queryClient.setQueryData(queryKey, data);
    } catch (err) {
      console.error(err);
    }
  };

  return [fetch, serviceAccount, { refetch }];
};
