import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Layout, Typography } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminLoginInput } from '../generated/api/axios-client';
import { useAdminLoginMutation } from '../generated/api/axios-client/AuthControllerQuery';
import { useAuth } from '../hooks/auth';
import { useNotification } from '../hooks/notification';

const { Content, Footer } = Layout;
const { Title } = Typography;

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const notification = useNotification();

  const adminLoginMutation = useAdminLoginMutation({
    onSuccess: (accessToken) => {
      setAuth(accessToken);
      navigate('/');
      setLoading(false);
    },
    onError: (a) => {
      console.log(a);
      notification.notify.error({
        message: '로그인에 실패했습니다',
        description: '관리자 ID와 비밀번호를 확인해주세요',
      });
      setLoading(false);
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    adminLoginMutation.mutate(
      new AdminLoginInput({
        email,
        password,
      }),
    );
  };

  return (
    <Layout className="min-h-screen flex flex-col">
      <Content className="flex flex-1 items-center justify-center">
        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
          <Title level={2} className="text-center mb-6">
            Charlla 관리자 로그인
          </Title>
          <Form
            name="login_form"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="adminId"
              rules={[
                { required: true, message: 'Please input your Admin Email!' },
              ]}
            >
              <Input
                prefix={<UserOutlined className="text-gray-400" />}
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="text-gray-400" />}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
      <Footer className="text-center">©2024 Catenoid</Footer>
    </Layout>
  );
};

export default LoginPage;
