import { Breadcrumb as AntdBreadCrumb } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useGetServiceAccountLazyQuery,
  useGetShopAccountLazyQuery,
} from '../hooks/fetch';

export const pathMap: any = {
  '/users': '업체 관리',
  '/create-user': '업체 계정 생성',
  '/withdraw-user': '탈퇴 관리',
  '/user': '업체 관리',
  '/service-accounts': '서비스 계정 관리',
};

const BreadCrumb: React.FC = () => {
  const location = useLocation();
  const [getShopAccount, data] = useGetShopAccountLazyQuery();
  const [getServiceAccount, serviceAccountData] =
    useGetServiceAccountLazyQuery();

  const breadCrumbItems = useMemo<ItemType[]>(() => {
    const splited = location.pathname.split('/').filter(Boolean);
    if (splited[0] === 'user') {
      const userId = splited[1];
      if (userId) {
        getShopAccount?.(Number(userId));
      }

      return [
        {
          title: pathMap['/user'],
          href: '/users',
        },
        {
          title: data ? data.mallName : undefined,
          href: `${process.env.REACT_APP_HOST}${location.pathname}`,
        },
      ];
    }

    if (splited[0] === 'service-account') {
      const serviceAccountId = splited[1];
      if (serviceAccountId) {
        getServiceAccount?.(Number(serviceAccountId));
      }

      return [
        {
          title: pathMap['/service-accounts'],
          href: '/service-accounts',
        },
        {
          title: serviceAccountData
            ? serviceAccountData.serviceName
            : undefined,
          href: `${process.env.REACT_APP_HOST}${location.pathname}`,
        },
      ];
    }

    let pathAccumulator = '';

    return splited
      .map((path) => {
        pathAccumulator += `/${path}`;
        const title = pathMap[pathAccumulator];

        pathAccumulator = `${process.env.REACT_APP_HOST}${pathAccumulator}`;

        if (title) {
          return { title, href: pathAccumulator };
        }

        return;
      })
      .filter((item): item is any => !!item);
  }, [location, data, serviceAccountData]);

  return <AntdBreadCrumb items={breadCrumbItems} />;
};

export default BreadCrumb;
